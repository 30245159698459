<template>
	<two-cards>
		<template #header>
			<h4 class="card-title">Filtros</h4>
		</template>

		<template #one>
			<form @submit.prevent="pesquisar">
				<div class="form-row">
					<div class="form-group col-sm-6">
						<label>Área</label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading"
							v-model="area"
							search
							historyButton
							:filters="multiSelectFilters[0]"
							searchPlaceholder="Pesquisar"
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="areasOptions"
							data-cy="Área"
							@input="filtraMaquinas"
							:btnLabel="() => (area[0] && area[0].nome) || 'Selecione'"
						/>
					</div>
					<div class="form-group col-sm-6">
						<label>Máquinas</label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading || !area.length"
							v-model="maquinasSelecionadas"
							search
							historyButton
							:filters="multiSelectFilters[1]"
							searchPlaceholder="Pesquisar"
							:options="multiSelectOptions"
							:selectOptions="maquinasOptions"
							data-cy="Máquinas"
							@input="filtraGrupos"
							:btnLabel="() => customLabel(maquinasSelecionadas)"
						/>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-sm-3">
						<label>Grupos *</label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading"
							v-model="gruposSelecionados"
							search
							historyButton
							:filters="multiSelectFilters[2]"
							searchPlaceholder="Pesquisar"
							:options="multiSelectOptions"
							:selectOptions="gruposOptions"
							data-cy="Grupos"
							@input="getVariaveis"
							:btnLabel="() => customLabel(gruposSelecionados)"
						/>
					</div>
					<div class="form-group col-sm-3">
						<label>Variáveis *</label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading || !gruposSelecionados.length"
							v-model="variaveis"
							search
							historyButton
							:filters="multiSelectFilters[3]"
							searchPlaceholder="Pesquisar"
							:options="multiSelectOptions"
							:selectOptions="variaveisOptions"
							data-cy="Variaveis"
							:btnLabel="() => customLabel(variaveis)"
						/>
					</div>
					<div class="col form-group col-sm-3">
						<label>Intervalo entre os pontos *</label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading"
							v-model="escalaEixoX"
							search
							historyButton
							:filters="multiSelectFilters[4]"
							searchPlaceholder="Pesquisar"
							disabledUnSelect
							:options="{ multi: false, labelName: '1' }"
							:selectOptions="escalaEixoXOptions"
							data-cy="Escala do eixo x"
							:btnLabel="() => escalaEixoX[0][1]"
						/>
					</div>
					<div class="form-group col-sm-3">
						<label>Limites</label>
						<vue-multi-select
							class="multi-100"
							:disabled="loading"
							v-model="limitesSelecionados"
							search
							historyButton
							:filters="multiSelectFilters[1]"
							searchPlaceholder="Pesquisar"
							:options="multiSelectOptions"
							:selectOptions="limitesOptions"
							data-cy="Limites"
							:btnLabel="() => (limitesSelecionados.length == 0 ? 'Selecione' : limitesSelecionados.join(', '))"
						/>
					</div>
				</div>
				<div class="form-row mt-2">
					<div
						class="col-lg-2 col-md-12 mb-1"
						style="font-size: 1.5rem"
					>
						Filtros rápidos:
					</div>
					<div class="col-lg-10 col-md-12">
						<button
							type="button"
							class="col-md-2 btn rounded-pill mr-3 mb-1"
							v-for="(f, idx) in FILTROS_RAPIDOS"
							:key="idx"
							@click="btnFiltroRapido(f)"
							:class="
								filtros.rapido === f
									? 'btn-info'
									: 'btn-outline-dark'
							"
							:data-cy="f.nome"
						>
							{{ f.nome }}
						</button>
					</div>
				</div>
				<p class="small text-right mt-2 mb-0">
					Campos marcados com * são obrigatórios.
				</p>
				<div class="d-flex justify-content-end tcs-card-footer-action">
					<button
						:disabled="!variaveis.length || dataLoading"
						class="btn btn-success tcs-btn"
						type="submit"
						data-cy="Pesquisar"
					>
						<SearchIcon/>
					</button>
				</div>
			</form>
		</template>
		<template #two>
			<tbody v-if="dataLoading">
				<tb-skeleton
					shape="rect"
					style="
						background-color: #dcdcdc;
						height: 25rem;
						width: 100%;
					"
					theme="opacity"
				></tb-skeleton>
			</tbody>
			<template v-else>
				<ECharts autoresize v-if="ready" :options="options" :style="divHeightControl"/>
				<p class="text-center my-auto" v-else>
					Selecione as variáveis que deseja visualizar.
				</p>
			</template>
		</template>
	</two-cards>
</template>

<script>
	import TwoCards from "../templates/TwoCards";
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";

	import { ptBR } from "vuejs-datepicker/dist/locale";

	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import ECharts from "vue-echarts";
	import optionsFactory from "../charts/timeseries.echarts";

	import "echarts/lib/chart/line";

	import { AreasService } from "@/services/areas";
	import { GruposService } from "@/services/grupos";
	import { MaquinasService } from "@/services/maquinas";
	import { TrendsService } from "@/services/trends";
	import { VariaveisService } from "@/services/variaveis";

	const DIGITAL_HEIGHT = 35.5;

	let updateInterval;

	export default {
		components: {
			TwoCards,
			VueMultiSelect,
			ECharts
		},

		data () {
			return {
				ptBR,
				area: [],
				maquinas: [],
				maquinasSelecionadas: [],
				grupos: [],
				gruposSelecionados: [],
				variaveis: [],
				escalaEixoX: [[1, "1 segundo"]],

				areasOptions: [],
				maquinasOptions: [],
				gruposOptions: [],
				variaveisOptions: [],
				escalaEixoXOptions: [
					[undefined, "Mínimo"],
					[1, "1 segundo"],
					[5, "5 segundos"],
					[15, "15 segundos"],
					[30, "30 segundos"],
					[60, "1 minuto"],
					[180, "3 minutos"],
					[300, "5 minutos"],
					[600, "10 minutos"],
					[900, "15 minutos"]
				],

				multiSelectFilters: [
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(),
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters()
				],
				multiSelectOptions: { multi: true },
				options: optionsFactory("Tendência Online", [], []),
				ready: false,
				limitesOptions: ["HH", "H", "L", "LL"],
				limitesSelecionados: [],
				limitesDict: {},
				yAxisLimits: {},
				pesquisaSolicitadaUsuario: false,

				FILTROS_RAPIDOS: [
					{
						nome: "Último minuto",
						inicio: [-1, "minute"],
						intervalo: 1
					},
					{
						nome: "Última hora",
						inicio: [-1, "hour"],
						intervalo: 30
					},
					{
						nome: "Último turno",
						inicio: [-8, "hours"],
						intervalo: 300
					},
					{
						nome: "Último dia",
						inicio: [-1, "day"],
						intervalo: 900
					}
				],

				filtros: {
					inicio: null,
					rapido: 0
				},

				filtrosAplicados: {
					dataPesquisa: 0,
					inicio: 0,
					variaveis: []
				},

				updating: false,
				errMsg: "",
				loading: true,
				showMarkLine: true,
				dataLoading: false,

				areasService: new AreasService(),
				gruposService: new GruposService(),
				maquinasService: new MaquinasService(),
				trendsService: new TrendsService(),
				variaveisService: new VariaveisService()
			};
		},

		async mounted () {
			this.loading = true;
			this.filtroRapido(this.FILTROS_RAPIDOS[0]);

			this.getGrupos();

			this.areasOptions = (await this.areasService.listAreas()) || [];
			this.maquinas = (await this.maquinasService.listMachines()).map(m => ({ name: m.nome, ...m })) || [];

			if (this.areasOptions.length === 1)
				this.area = this.areasOptions;
		},

		methods: {
			filtraMaquinas () {
				this.maquinasSelecionadas = [];
				if (this.area[0]) {
					this.maquinasOptions = this.maquinas.filter(m => m.id_area == this.area[0].id);

					const idMaquinas = this.maquinasOptions.map(m => m.id);
					this.gruposOptions = this.grupos.filter(g => idMaquinas.includes(g.id_maquina));
				} else {
					this.gruposOptions = this.grupos.slice();
				}

				this.$forceUpdate();
			},

			filtraGrupos () {
				this.gruposSelecionados = [];

				if (this.maquinasSelecionadas.length) {
					this.gruposOptions = this.grupos.filter(g => this.maquinasSelecionadas.find(m => m.id === g.id_maquina));
				} else if (this.area.length) {
					const idMaquinas = this.maquinasOptions.map(m => m.id);
					this.gruposOptions = this.grupos.filter(g => idMaquinas.includes(g.id_maquina));
				} else {
					this.gruposOptions = this.grupos.slice();
				}

				this.$forceUpdate();
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},

			digitalVars () {
				return this.filtrosAplicados.variaveis.filter(v => v.tipo === "DIGITAL").length;
			},

			toggleMarkLine () {
				this.showMarkLine = !this.showMarkLine;
				for (const serie of this.options.series) {
					if (!serie.markLine) continue;

					if (this.showMarkLine && "disabledData" in serie.markLine) {
						serie.markLine.data = serie.markLine.disabledData;
						delete serie.markLine.disabledData;
					} else {
						serie.markLine.disabledData = serie.markLine.data;
						serie.markLine.data = [];
					}
				}
			},

			customLabel (selected) {
				return selected.length ?
					`${selected.length} selecionado${selected.length > 1 ? "s" : ""}` : "Selecione";
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("DD/MM/YYYY HH:mm:ss");
			},

			async getGrupos () {
				this.loading = true;

				this.grupos = ((await this.gruposService.listGroups()) || []).map(g => ({ name: g.nome, ...g }));
				this.filtraGrupos();

				this.loading = false;
			},

			async getVariaveis () {
				this.variaveis = [];
				this.loading = true;

				const idGrupos = this.gruposSelecionados.map(g => g.id);
				if (idGrupos.length === 0) {
					this.variaveisOptions = [];
				} else {
					this.variaveisOptions = ((await this.variaveisService.listVariables(idGrupos)) || [])
						.map(v => ({ name: `${v.tag} (${v.id} - ${v.descricao})`, ...v }));
				}

				this.loading = false;
			},

			filtroRapido (fr) {
				this.filtros.rapido = fr;
				if (fr.inicio)
					this.filtros.inicio = dayjs().add(...fr.inicio).toDate();

				if (fr.agrupamento)
					this.filtros.agrupamento = fr.agrupamento;

				if (fr.intervalo)
					this.escalaEixoX = [this.escalaEixoXOptions.find(e => e[0] === fr.intervalo)];
			},

			btnFiltroRapido (fr) {
				if (this.filtros.rapido !== fr)
					this.filtroRapido(fr);
			},

			async pesquisar () {
				this.filtros.inicio = dayjs().add(...this.filtros.rapido.inicio).toDate();

				this.filtrosAplicados.dataPesquisa = Date.now();
				this.filtrosAplicados.filtroRapido = this.filtros.rapido;
				this.filtrosAplicados.inicio = this.filtros.inicio;
				this.filtrosAplicados.variaveis = JSON.parse(JSON.stringify(this.variaveis));
				this.filtrosAplicados.intervalo = this.escalaEixoX[0][0];

				clearInterval(updateInterval);

				// Clear chart
				this.options = optionsFactory(
					"Tendência Online",
					[],
					[],
					"s",
					this.toggleMarkLine
				);

				this.dataLoading = true;
				this.pesquisaSolicitadaUsuario = true;
				await this.update();

				updateInterval = setInterval(() => {
					if (this.ready) this.update();
				}, (this.filtrosAplicados.intervalo || 1) * 1000);

				this.dataLoading = false;
			},

			async update () {
				if (this.updating) return;
				this.updating = true;

				try {
					this.filtrosAplicados.inicio = dayjs().add(...this.filtrosAplicados.filtroRapido.inicio).toDate();

					let dados, data;
					if (this.pesquisaSolicitadaUsuario) {
						this.pesquisaSolicitadaUsuario = false;
						dados = await this.trendsService.getOnlineData(
							this.filtrosAplicados.variaveis.map(v => v.id),
							this.filtrosAplicados.inicio,
							this.filtrosAplicados.intervalo,
							this.limitesSelecionados
						);
						data = dados.measurements;
						const limites = dados.limitesRetorno || [];

						for (let i = 0; i < limites.length; i++) {
							this.limitesDict[limites[i].id_variavel] = [];
							for (let j = 0; j < this.limitesSelecionados.length; j++) {
								const obj = {};
								const key = this.limitesSelecionados[j].toLowerCase();
								if (limites[i][key] != null) {
									obj.name = this.limitesSelecionados[j] + " - " + limites[i]["variavel.tag"] + " (" + limites[i]["variavel.descricao"] + ")";
									obj.yAxis = limites[i][key];
									obj.tooltip = {
										formatter (params) {
											return params.data.name;
										}
									};
									obj.itemStyle = {
										color: ["hh", "ll"].includes(key) ? "#EC1C24" : "#FAAF40"
									};
									this.limitesDict[limites[i].id_variavel].push(obj);
								}
							}
						}

						if (limites.length) {
							this.yAxisLimits.min = dados.minMaxValues.minValor;
							this.yAxisLimits.max = dados.minMaxValues.maxValor;
						}
					} else {
						dados = await this.trendsService.getOnlineData(
							this.filtrosAplicados.variaveis.map(v => v.id),
							this.filtrosAplicados.inicio,
							this.filtrosAplicados.intervalo,
						);
						data = dados.measurements;
					}

					let digitalIndex = 0;
					const digitalCount = this.digitalVars();

					this.options.legend.data = this.filtrosAplicados.variaveis.map(v => v.name);
					this.options.series = this.filtrosAplicados.variaveis.map(v => ({
						analogica: v.analogica,
						digital: v.digital,
						tipo: v.tipo,
						name: v.name,
						type: "line",
						connectNulls: true,
						yAxisIndex: v.digital ? 0 : 1,
						xAxisIndex: v.digital ? 0 : 1,
						step: v.tipo === "DIGITAL" ? "end" : undefined,
						symbol: "roundRect",
						symbolSize: 4,
						hoverAnimation: false,
						data: data.reduce((r, m) => {
							if (!Object.keys(v).find(k => k === "bottom"))
								v.bottom = v.tipo === "DIGITAL" ? digitalIndex++ * 2 : 0;

							if (m.id_variavel != v.id || m.valor === null)
								return r;

							r.push({
								name: m.data_hora_interval,
								value: [m.data_hora_interval, m.valor + v.bottom]
							});
							return r;
						}, []),
						markLine: {
							data: this.showMarkLine ? this.limitesDict[v.id] : []
						}
					}));

					this.options.tooltip = {
						trigger: "item",
						formatter: params => {
							const serie = this.options.series[params.seriesIndex];
							const dateFormat = this.filtrosAplicados.intervalo ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY HH:mm:ss.SSS";
							if (serie.tipo === "ANALOGICA") {
								return `
									${dayjs(params.value[0]).format(dateFormat)}<br>
									${params.marker}
									${params.seriesName}: ${params.value[1]} ${serie.analogica ? serie.analogica.unidade : ""}
								`;
							} else {
								return `
									${dayjs(params.value[0]).format(dateFormat)}<br>
									${params.marker}
									${params.seriesName}: ${serie.digital ? (params.value[1] % 2 === 0 ? serie.digital.estado_off : serie.digital.estado_on) : params.value[1]}
								`;
							}
						}
					};

					this.options.axisPointer = {
						link: { xAxisIndex: "all" }
					};

					this.options.grid = [{
						x: 80,
						position: "top",
						y2: 65,
						gridIndex: 0
					}, {
						x: 80,
						position: "bottom",
						y: 65,
						y2: 115 + DIGITAL_HEIGHT * (digitalCount * 2 - 1),
						gridIndex: 1
					}];

					this.options.xAxis = [{
						type: "time",
						show: digitalCount > 0 || digitalCount === null,
						gridIndeX: 0,
						axisLabel: {
							formatter: this.formatter
						}
					}, ...(digitalCount !== null ? [{
						type: "time",
						gridIndex: 1,
						axisLabel: {
							formatter: this.formatter
						}
					}] : [])];

					/**
					 * Configuração dos eixos Y
					 * @type {[Axis, Axis]}
					 * 0: axis de digitais,
					 * 1: axis de analógicas
					 */
					this.options.yAxis = [{
						type: "value",
						min: 0,
						max: DIGITAL_HEIGHT / 5 + digitalCount * 2,
						show: false,
						tag: "DIGITAL",
						gridIndex: 0
					}, {
						type: "value",
						gridIndex: 1
					}];

					if ("min" in this.yAxisLimits)
						this.options.yAxis[1].min = this.yAxisLimits.min;

					if ("max" in this.yAxisLimits)
						this.options.yAxis[1].max = this.yAxisLimits.max;

					this.options.toolbox.right = 17;
					this.options.toolbox.feature.dataZoom = {
						yAxisIndex: 1,
						xAxisIndex: 1,
						title: {
							zoom: "zoom de área",
							back: "voltar zoom"
						}
					};

					this.options.dataZoom[0].top = 29;
					this.options.dataZoom[2].top = 65;
					this.options.dataZoom[2].yAxisIndex = 1;
					this.options.dataZoom[0].xAxisIndex = [0, 1];
					this.options.dataZoom[1].xAxisIndex = [0, 1];

					this.ready = true;
				} catch (err) {
					console.log(err);
					this.$snotify.error(
						"Não foi possível atualizar o gráfico online. Tentando novamente...",
						{ timeout: 3000 }
					);
				}

				this.updating = false;
			}
		},

		computed: {
			divHeightControl () {
				const digitalCount = this.digitalVars();
				const heightAdd = DIGITAL_HEIGHT / 5 + digitalCount * 2 * 30;
				const newHeight = 450 + heightAdd;

				return "height: " + newHeight + "px";
			}
		},

		beforeDestroy () {
			clearInterval(updateInterval);
		}
	};
</script>

<style>
	.echarts {
		min-height: 450px;
		width: 100%;
	}
</style>
